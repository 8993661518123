@media only screen and (max-width: 576px){
    .flowpaper-div iframe{
        height: 30rem;
        width: 18rem;
    }
}


@media only screen and (max-width: 320px) {
    .flowpaper-div iframe{
        height: 30rem;
        width: 18rem;
    }
}
