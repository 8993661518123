@import "variables";
@import "~bootstrap/scss/bootstrap";

.flowpaper-div iframe{
    height: 50rem;
    width: 18rem;
}

.flowpaper_bttnDownload{
display:none !important;
}

.flowpaper_tbbutton{
display:none !important;
}

.download{
display:none !important;
}


html,body{
  font-family: "Heebo-regular";
  font-size: 14px;
  text-align: justify !important;
}

.container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .react-pdf__Page__textContent textLayer{
    display:none;
  }

  .catalog-title{
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
    margin: auto;
  }


.gmail-icon, .whatsapp-icon, .download-icon{
  cursor: pointer;
  width: 5rem;
  height: 5rem;
}

.login-title{
  font-size: 3rem;
  // font-family: "Heebo-600 !important";

}
.form-control{
  margin: 1rem 0 1rem 0;
  border-radius: 12px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 1.5rem;
}

.modalCloseIcon {
  color: $primary-green-color;
  right: -0.6rem;
  top: -0.7rem;
  position: absolute;
  background-color: white;
  border-radius: 3rem;
  height: 2.5rem;
  cursor: pointer;
  z-index:1;
}

.successNote{
  color: $primary-green-color;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}


.modal-content {
  border-radius: 35px;
  padding: 4rem; }


.invalid-feedback {
  // margin-top: 0.25rem;
  font-size: 1.3em;
}

h1.page-title {
  font-weight: bold;
  // font-family: "Heebo-600 !important";
}

.card{
  border-radius: 16px;
}

.card-row{
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
}


.logo {
  width: 120px; 
}

@media (max-width: 567px) {
    .logo {
        width: 90px; 
    }
    .login-title {
        font-size: 2.5rem;
    }
    .page-title {
        font-size: 2.5rem;
    }

    .gmail-icon, .whatsapp-icon, .download-icon{
        width: 2.5rem;
        height: 2.5rem;
    }
} 

.modal-content {
  padding: 1.5rem;
}

.successModal .text{
  font-weight: 100;
  font-size: 16px;
  line-height: 1.3rem;
  padding: 0.4rem 0.5rem 0 3rem;
}